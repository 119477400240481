@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'RadwaveOutline';
  src: local('RadwaveFont-Outline'),
    url(./assets/fonts/RadwaveFont-Outline.otf) format('opentype');
}

@font-face {
  font-family: 'RadwaveRegular';
  src: local('RadwaveFont-Regular'),
    url(./assets/fonts/RadwaveFont-Regular.otf) format('opentype');
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
  background-color: #f9fafb;
}

:root {
  /* Set neon color */
  --neon-text-color: #f40;
  --neon-border-color: #08f;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.bg-grid {
  background-color: rgb(243 244 246);
  background-color: white;
  background-image: linear-gradient(#dcefde00 1px, transparent 1px),
    linear-gradient(90deg, #d4efd800 1px, transparent 1px),
    linear-gradient(#f0f0f098 1px, transparent 1px),
    linear-gradient(90deg, #f0f0f098 1px, transparent 1px);
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  animation: primary 30s linear infinite;
}

@keyframes primary {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: -30% 100%;
  }
}

.bg-metal {
  background-image: linear-gradient(
    45deg,
    #000000e3,
    #1e1e1ee8,
    #363636d9,
    #0000008a,
    #6262625c
  );
}

.swipe-animation {
  animation: swipe 2s infinite;
}

@keyframes swipe {
  0% {
    width: 0%;
    padding-left: 0%;
    opacity: 100;
  }
  100% {
    padding-left: 100%;
    opacity: 0;
  }
}

.swipe-animation-reverse {
  animation: swipe-reverse 2s infinite;
}

@keyframes swipe-reverse {
  0% {
    width: 0%;
    margin-left: 100%;
    padding-left: 0%;
    opacity: 100;
  }
  100% {
    margin-left: 0%;
    padding-left: 100%;
    opacity: 0;
  }
}

.custom-button > div > button {
  box-shadow: 0px 1px 1px #e5e7eb72 !important;
  border: 1px solid #e5e7eb !important;
}

.custom-button-initial > div > button {
  box-shadow: 0px 1px 1px #e5e7eb72 !important;
}

.spin-3d-animation {
  animation: spin-3d 5s infinite;
}

@keyframes spin-3d {
  0% {
    transform: rotate3d(1, 1, 1, 0deg);
  }
  100% {
    transform: rotate3d(1, 1, 1, 360deg);
  }
}

.data-rk {
  height: 100%;
}

.card-overlay-shadow-sm {
  box-shadow: inset 0px -2px 2px 0px #c2c2c25c;
  -webkit-box-shadow: inset 0px -2px 2px 0px #c2c2c25c;
  -moz-box-shadow: inset 0px -2px 2px 0px #c2c2c25c;
}

.card-overlay-shadow {
  box-shadow: inset 0px -4px 5px 0px #c2c2c245;
  -webkit-box-shadow: inset 0px -4px 5px 0px #c2c2c245;
  -moz-box-shadow: inset 0px -4px 5px 0px #c2c2c245;
}

.card-overlay-none {
  box-shadow: inset 0px 0px 0px 0px #ffffff00;
  -webkit-box-shadow: inset 0px 0px 0px 0px #ffffff00;
  -moz-box-shadow: inset 0px 0px 0px 0px #ffffff00;
}
